import React from 'react'
import commonAction from '../../actions/common'
import { connect } from 'react-redux'
import './WorkOrderDetails.scss'
import WhiteSpace from '../../components/WhiteSpace'
import { getOrderDetail, saveOrder, startOrder, orderDistance } from '../../api/WorkOrderDetails'
import { batchUploadFileByBase64, uploadFileByBase64, getWechatConfig, wxValidationPermissions } from '../../api/Sys'
import { getUserSimpleList } from '@/api/ElevatorMaintenance';
import Tabs from '../../components/Tabs'
import MainSwiper from './components/MainSwiper'
import { ImagePicker, Toast } from 'antd-mobile';
import Drawer from '../../components/Drawer'
import Signature from '../../components/Signature'
import wx from 'weixin-js-sdk'
import Lodding from '../../components/Lodding'
import transformFile from '@/libs/imageCompression'
import Dialog from '@/components/Dialog'
import Select from '../../components/Select'
import { getLiftDetail } from '@/api/LiftLocation'

function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


class WorkOrderDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      detail: {},
      locationDTOList: [],
      locationDTOListName: [],
      scenePhoto: [],
      orderPhoto: [],
      open: false,
      lat: 0,
      address: "",
      animating: false,
      status: 4, //工单状态
      signatureImage: [],
      remark: '',
      replacementParts: '',
      showDialog: false,
      showStartDialog: false,
      peoples: [],
      peopleList: [], //接口返回的数据
      openSignature3: false,
      juli: null

    }
    this.postData = {}
    // 工单状态 2 中途离开 4 结束
    this.submitStatus = 2
    this.maintainOrderId = null;
    this.canSearch = true
    this.currentPeople = null
    this.lat = null
    this.lon = null
  }
  getUserSimpleListFun = async (v = '') => {
    this.canSearch = false
    const res = await getUserSimpleList({ keyword: v, size: 1000, });
    if (res.code === 200) {
      this.setState({
        peopleList: res.data
      })
    }
    this.canSearch = true
  }

  // 搜索项目
  handleSearch = (val) => {
    if (this.canSearch) {
      this.getUserSimpleListFun(val)
    }
  }
  // 下拉框聚焦
  handleFocus = () => {
    this.getUserSimpleListFun()
  }
  // 返回当前选中的值
  handleChoose = (v, item) => {
    let arr = []
    arr = [...this.state.peoples]
    const index = arr.findIndex(i => i.gid === item.gid)
    arr[index].teamworkUserId = v.userId
    arr[index].teamworkUserName = v.nickname
    arr[index].userId = v.userId
    arr[index].nickname = v.nickname
    this.setState({
      peoples: arr
    })
  }

  addItem = () => {
    const arr = [...this.state.peoples]
    const obj = {
      gid: guid(),
      imageUrl: null,
      rate: null,
      teamworkUserId: null,
      teamworkUserName: null
    }
    arr.push(obj)
    this.setState({
      peoples: arr
    })
  }

  moreInputChange = (gid, e) => {
    const arr = [...this.state.accessories]
    const index = arr.findIndex(item => item.gid === gid)
    arr[index].useAmount = e.target.value
    this.setState({
      accessories: arr
    })
  }

  showSig = (item) => {
    this.currentPeople = { ...item }
    this.setState({
      openSignature3: true
    })
  }

  // 获取签名的base64 然后提交服务器
  getSignature3 = async (base64) => {
    this.setState({
      animating: true
    })
    const res1 = await this.submitBase64List()
    if (res1) {
      const res = await uploadFileByBase64(base64)
      if (res.code === 200) {
        // this.submitStatus = 4
        // this.signatureImageDataList.push({ imageUrl: res.data.url })
        // this.getLocation()
        const arr = [...this.state.peoples]
        const index = arr.findIndex(item => this.currentPeople.gid === item.gid)
        arr[index].imageUrl = res.data.url
        this.setState({
          peoples: arr,
          openSignature3: false
        })
      }
    }
    this.setState({
      animating: false
    })
  }

  deleteItem = (gid, type) => {
    let arr = []
    if (type === 'a') {
      arr = [...this.state.accessories]
    } else {
      arr = [...this.state.peoples]
    }
    const index = arr.findIndex(item => item.gid === gid)
    arr.splice(index, 1)
    if (type === 'a') {
      this.setState({
        accessories: arr
      })
    } else {
      this.setState({
        peoples: arr
      })
    }

  }

  componentDidMount() {
    const pathname = this.props.path;
    this.props.dispatch({ type: commonAction.setPathname, pathname })
    this.props.onRef(this);
    this.getWechatConfigFun()
    // this.startOrderFun(this.props.match.params.id * 1)
    this.getOrderDetailFun(this.props.match.params.id * 1)



    this.addItem()
  }

  // 开始工单
  startOrderFun = (orderId) => {
    startOrder({ orderId })
  }
  // 判断是不是ios
  isIos = () => {
    const u = navigator.userAgent;
    return u.indexOf("iPhone") > -1 || u.indexOf("Mac OS") > -1;
  }
  submit = () => {
    this.props.history.push({ pathname: `/layout/log/${this.props.match.params.id}` });
  }

  // 获取电梯详情
  getLiftDetailFun = async (liftId) => {
    const res = await getLiftDetail({ liftId })
    if (res.code === 200) {
      this.lat = res.data.lat
      this.lon = res.data.lon
      if (!this.lat || !this.lon) {
        Toast.fail("暂无位置信息", 2)
        return
      }
      const that = this
      wx.ready(function () {
        wx.checkJsApi({
          jsApiList: ["getLocation"],
          success(res) {
            if (res.checkResult.getLocation === false) {
              const message =
                "你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！";
              console.log(message);
            } else {
              wx.getLocation({
                type: 'wgs84',
                success: (res) => {
                  console.log("getLocation res=", res)
                  const BMap = window.BMap
                  const ggPoint = new BMap.Point(res.longitude, res.latitude)
                  var convertor = new BMap.Convertor();
                  var pointArr = [];
                  pointArr.push(ggPoint);
                  convertor.translate(pointArr, 1, 5, (res) => {
                    if (res.status === 0) {
                      const nowLon = res.points[0].lng
                      const nowLat = res.points[0].lat
                      orderDistance({ lngFrom: nowLon, lngTo: that.lon, latFrom: nowLat, latTo: that.lat }).then((res) => {
                        if (res.code === 200) {
                          that.setState({
                            juli: res.data || null
                          })
                        }
                      })
                    }
                  })
                },
                error: (error) => {
                  console.log("getLocation error=", error)

                }

              })
            }
          },
        })
      })
      wx.error(function (res) {
        console.log(res, 'res')
      })
    }
  }

  reset = () => {
    this.getLiftDetailFun(window.liftId)
  }

  async getOrderDetailFun(orderId) {
    this.maintainOrderId = orderId;
    const res = await getOrderDetail({ orderId })

    if (res.code === 200) {
      window.liftId = res.data.liftId
      this.getLiftDetailFun(window.liftId)
      let tabsNames = []
      res.data.locationDTOList.forEach(item => {
        tabsNames.push(item.locationName)
      })
      let liveImageDataList = []
      res.data.liveImageDataList.forEach((item, index) => {
        liveImageDataList.push({
          id: index,
          url: item.imageUrl
        })
      })
      let orderImageDataList = []
      res.data.orderImageDataList.forEach((item, index) => {
        orderImageDataList.push({
          id: index,
          url: item.imageUrl
        })
      })
      let signatureImage = []
      if (res.data.signatureImageDataList && res.data.signatureImageDataList.length) {
        signatureImage = res.data.signatureImageDataList
      }
      const peoples = res.data.maintainOrderTeamworkList.map(item => ({ ...item, gid: guid(), userId: item.teamworkUserId, nickname: item.teamworkUserName }))
      console.log(peoples)
      this.setState({
        detail: res.data,
        locationDTOList: res.data.locationDTOList,
        locationDTOListName: tabsNames,
        scenePhoto: liveImageDataList,
        orderPhoto: orderImageDataList,
        status: res.data.status,
        signatureImage,
        replacementParts: res.data.replacementParts,
        peoples,
        remark: res.data.remark
      }, () => { this.checkOrder(); this.getUserSimpleListFun(); })
    }
  }
  format = (num) => {
    let str = num >= 10 ? num : '0' + num
    return str
  }
  checkOrder = () => {
    const needDate = new Date()
    let needYear = needDate.getFullYear()
    let needMonth = this.format(needDate.getMonth() + 1)
    let needDay = this.format(needDate.getDate())
    let date = `${needYear}-${needMonth}-${needDay}`
    if (this.state.detail.status !== 4 && date !== this.state.detail.planTime) {
      this.setState({
        showDialog: true
      })
    } else {
      this.setState({
        showStartDialog: true
      })
    }

  }

  handleCancle = () => {
    this.props.history.goBack(-1)
  }

  handleConfirm = () => {
    this.setState({
      showDialog: false
    })
    this.startOrderFun(this.props.match.params.id * 1)
  }

  handleStartConfirm = () => {
    this.setState({
      showStartDialog: false
    })
    this.startOrderFun(this.props.match.params.id * 1)
  }

  onMainSwiperRef = (ref) => {
    this.mainSwiper = ref
  }

  onTabsRef = (ref) => {
    this.tabs = ref
  }

  changeTab = (index) => {
    if (this.mainSwiper) {
      this.mainSwiper.changeSlide(index);
    }
  }

  touchIndex = (index) => {
    this.tabs.changeSwuper(index)
  }


  scenePhotoChange = async (files, type, index) => {
    this.setState({
      scenePhoto: files
    })
  }

  orderPhotoChange = (files, type, index) => {
    this.setState({
      orderPhoto: files
    })
  }

  // 弹出签名弹窗
  clickSubmit = async () => {
    if (this.state.juli === null || this.state.juli > 700) {
      Toast.fail("异地不能提交", 2)
      return
    }
	if(this.state.peoples.length == 0 && this.state.detail.maintainType > 1) {
		Toast.fail("请添加协同人", 2)
		return
	}

	var flag = this.state.peoples.filter(
	s => (s == null || s == undefined ||  s.imageUrl == null || s.imageUrl == undefined || s.imageUrl == '')
	);
	if(flag.length > 0) {
		Toast.fail("协同人请签字", 2)
		return
	}
    const lists = this.mainSwiper.getNewData()
    let str = ''
    lists.some(list => {
      const item = list.itemDTOList.find(item => item.optionalStatus == 1 && !item.result)
      if (item) {
        str = `"${list.locationName}"_"${item.itemName}"_"${item.requirements}"必须选择`
        return true
      }
    })
    console.log(str)
    if (str) {

      Toast.fail(str, 5)

      return
    }

    this.setState({
      open1: true
    })

  }

  // 签完字确定后保存数据
  save = async () => {
    this.postData.submitStatus = this.submitStatus
    this.postData.remark = this.state.remark
    this.postData.replacementParts = this.state.replacementParts
    this.postData.signatureImageDataList = this.signatureImageDataList
    this.postData.orderTeamworkParamList = this.state.peoples
    if (this.maintainOrderId) {
      this.postData.orderId = this.maintainOrderId;
    }
    const res = await saveOrder(this.postData)
    if (res.code === 200) {
      Toast.success("保存成功", 2)
      this.setState({
        animating: false,
        open: false
      }, () => {
        this.props.history.goBack()
      })
    } else {
      Toast.fail("保存失败", 2)
      this.setState({
        animating: false,
        open: false
      })
    }

  }

  // 关闭弹窗
  handleClose = async (v) => {
    this.setState({
      ['open' + v]: false
    })
  }

  // 获取签名的base64 然后提交服务器
  getSignature2 = async (base64) => {
    this.setState({
      animating: true
    })
    const res1 = await this.submitBase64List()
    if (res1) {
      const res = await uploadFileByBase64(base64)
      if (res.code === 200) {
        this.submitStatus = 4
        this.signatureImageDataList.push({ imageUrl: res.data.url })
        // this.getLocation()
        // this.postData.lon = 120.298501
        // this.postData.lat = 30.41875
        // this.postData.address = '余杭区'
        // this.save()
      }
    }
  }

  // 获取签名的base64 然后提交服务器
  getSignature = async (base64) => {
    this.setState({
      animating: true
    })
    const res1 = await this.submitBase64List()
    if (res1) {
      const res = await uploadFileByBase64(base64)
      if (res.code === 200) {
        this.signatureImageDataList = [{ imageUrl: res.data.url }]
      }
      this.submitStatus = 4
      this.getLocation()
    }

    // this.setState({
    //   animating: false,
    //   open2: true
    // })
  }
  // 提交base64图片到服务器
  submitBase64List = async () => {
    this.postData = { ...this.state.detail }
    this.postData.locationDTOList = await this.mainSwiper.getNewData()
    let orderImageDataList = []
    let liveImageDataList = []
    let orderImageBase64ListFinish = []
    let liveImageBase64ListFinish = []
    if (this.state.orderPhoto && this.state.orderPhoto.length) {
      const orderImageBase64List = []

      for (let i = 0; i < this.state.orderPhoto.length; i++) {
        if (this.state.orderPhoto[i].file) {
          orderImageBase64List.push(await transformFile(this.state.orderPhoto[i]))
        } else {
          orderImageBase64ListFinish.push({
            imageType: 2,
            imageUrl: this.state.orderPhoto[i].url
          })
        }
      }

      // this.state.orderPhoto.forEach(item => {
      //   if (item.file) {
      //     orderImageBase64List.push(item.url)
      //   } else {
      //     orderImageBase64ListFinish.push({
      //       imageType: 2,
      //       imageUrl: item.url
      //     })
      //   }
      // })
      const orderImageDataListRes = await batchUploadFileByBase64({ base64List: orderImageBase64List })
      if (orderImageDataListRes.code === 200) {
        orderImageDataList = orderImageDataListRes.data.map(item => {
          const obj = {
            imageType: 2,
            imageUrl: item.url
          }
          return obj
        })
      }
    }
    if (this.state.scenePhoto && this.state.scenePhoto.length) {
      const liveImageBase64List = []
      for (let i = 0; i < this.state.scenePhoto.length; i++) {
        if (this.state.scenePhoto[i].file) {
          liveImageBase64List.push(await transformFile(this.state.scenePhoto[i]))
        } else {
          liveImageBase64ListFinish.push({
            imageType: 2,
            imageUrl: this.state.scenePhoto[i].url
          })
        }
      }
      // this.state.scenePhoto.forEach(item => {
      //   if (item.file) {
      //     liveImageBase64List.push(item.url)
      //   } else {
      //     liveImageBase64ListFinish.push({
      //       imageType: 1,
      //       imageUrl: item.url
      //     })
      //   }
      // })
      const liveImageDataListRes = await batchUploadFileByBase64({ base64List: liveImageBase64List })
      if (liveImageDataListRes.code === 200) {
        liveImageDataList = liveImageDataListRes.data.map(item => {
          const obj = {
            imageType: 1,
            imageUrl: item.url
          }
          return obj
        })
      }
    }
    this.postData.orderImageDataList = orderImageBase64ListFinish.concat(orderImageDataList)
    this.postData.liveImageDataList = liveImageBase64ListFinish.concat(liveImageDataList)
    return true
  }
  // 中途离开
  liveSubmit = async () => {
    if (this.state.juli === null || this.state.juli > 700) {
      Toast.fail("异地不能提交", 2)
      return
    }
    this.setState({
      animating: true
    })
    this.submitStatus = 2
    if (await this.submitBase64List()) {
      this.getLocation()
      // this.postData.lon = 120.298501
      // this.postData.lat = 30.41875
      // this.postData.address = '余杭区'
      // this.save()
    }
  }

  // 获取授权
  getWechatConfigFun = async () => {
    console.log('授权发起')
    let url = window.location.href.split("#")[0];
    if (this.isIos()) {
      url = localStorage.getItem('url')
    }
    const res = await getWechatConfig(url)
    if (res.code === 200) {
      const data = { ...res.data }
      data.jsApiList = ['getLocation']
      wxValidationPermissions(data)
    }
  }

  // 获取地理位置
  getLocation = () => {
    const that = this
    wx.ready(function () {
      wx.checkJsApi({
        jsApiList: ["getLocation"],
        success(res) {
          if (res.checkResult.getLocation === false) {
            const message =
              "你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！";
            console.log(message);
          } else {
            wx.getLocation({
              type: 'wgs84',
              success: (res) => {
                console.log("getLocation res=", res)
                const BMap = window.BMap
                const ggPoint = new BMap.Point(res.longitude, res.latitude)
                var convertor = new BMap.Convertor();
                var pointArr = [];
                pointArr.push(ggPoint);
                const geoc = new BMap.Geocoder()
                convertor.translate(pointArr, 1, 5, (res) => {
                  if (res.status === 0) {
                    that.postData.lon = res.points[0].lng
                    that.postData.lat = res.points[0].lat
                    geoc.getLocation(res.points[0], function (rs) {
                      that.setState({
                        address: rs.address
                      })
                      that.postData.address = rs.address
                      that.save()
                    })
                  } else {
                    that.save()
                  }
                })
              },
              error: (error) => {
                console.log("getLocation error=", error)
                that.save()
              }

            })
          }
        },
      })
    })
    wx.error(function (res) {
      console.log(res, 'res')
    })


  }

  // 备注信息
  handleRemarkChange = (event) => {
    this.setState({ remark: event.target.value });
  }

  // 更换配件信息
  handleReplacementParts = (event) => {
    this.setState({ replacementParts: event.target.value });
  }

  // 关签名弹窗
  handleCloseSignature = (v) => {
    console.log(v)
    this.setState({
      ['openSignature' + v]: false
    })
  }








  render() {
    const { detail, scenePhoto, orderPhoto, locationDTOList, showDialog, showStartDialog, peoples, peopleList, juli } = this.state

    return (

      <div className="workOrderDetails">
        <div className="top">
          <ul>
            <li>
              工单编号<span>{detail.orderNo}</span>
            </li>
            <li>
              电梯编码<span>{detail.liftCode}</span>
            </li>
            <li>
              电梯位置<span>{detail.liftLocation}</span>
            </li>
            <li>
              计划时间<span>{detail.planTime}</span>
            </li>
            <li>
              保养类型<span>{detail.maintainTypeName}</span>
            </li>
            <li>
              客户等级<span>{detail.grade}</span>
            </li>
            <li>
              距&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;离<b>&nbsp;&nbsp;{juli === null ? '--' : juli}</b>
              <span onClick={this.reset}>刷新</span>
            </li>
          </ul>
        </div>
        <WhiteSpace />
        <div className="bottom">
          <Tabs list={this.state.locationDTOListName} changeTab={this.changeTab} onRef={this.onTabsRef} />
          <ul className="bottomItem">
            <li><i></i><span>正常</span></li>
            <li><i></i><span>出错</span></li>
            <li><i></i><span>无此项</span></li>
            <li><i></i><span>已整改</span></li>
            <li><i></i><span>已更换</span></li>
            <li><i style={{ backgroundColor: '#ccc' }}></i><span>待提交</span></li>
          </ul>
          <div className="mainSwiperBox">
            <MainSwiper status={this.state.status} onRef={this.onMainSwiperRef} list={locationDTOList} touchIndex={this.touchIndex} />
          </div>
          <div className="textAreaBox">
            <h6>备注信息</h6>
            <textarea placeholder="无" disabled={this.state.status === 4} value={this.state.remark} onChange={this.handleRemarkChange} />
          </div>
          <div className="textAreaBox">
            <h6>更换配件信息</h6>
            <textarea placeholder="无" disabled={this.state.status === 4} value={this.state.replacementParts} onChange={this.handleReplacementParts} />
          </div>
          <div className="textAreaBox moreAddBox">
            <h6 onClick={() => this.addItem('p')}>协同人员 +</h6>
            {
              peoples.map((item) => <div key={item.gid}>
                <Select label="nickname" handleChoose={(v) => this.handleChoose(v, item, 'p')} handleFocus={() => this.handleFocus('p')} valueKey="userId" chooseData={item} search={(v) => this.handleSearch(v, 'p')} list={peopleList} />
                <a href="javascript:;" onClick={() => this.showSig(item)}>{item.imageUrl ? '已签名' : '签名'}</a>
                <span onClick={() => this.deleteItem(item.gid, 'p')}>-</span>
              </div>)
            }
          </div>
          {
            this.state.status === 4 && !scenePhoto.length ? "" : (
              <div className="fileUploadBox">
                <h6>现场照片</h6>
                <ImagePicker
                  className={this.state.status === 4 ? "displayClose" : ""}
                  files={scenePhoto}
                  onChange={this.scenePhotoChange}
                  selectable={scenePhoto.length < 5 && this.state.status !== 4}
                  multiple={true}
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                />
              </div>
            )
          }

          {
            this.state.status === 4 && !orderPhoto.length ? "" : (
              <div className="fileUploadBox">
                <h6>工单照片</h6>
                <ImagePicker
                  className={this.state.status === 4 ? "displayClose" : ""}
                  files={orderPhoto}
                  onChange={this.orderPhotoChange}
                  selectable={orderPhoto.length < 5 && this.state.status !== 4}
                  multiple={true}
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                />
              </div>)
          }

          {
            this.state.signatureImage.map(item => {
              return (
                <div className="fileUploadBox" key={item.id}>
                  <h6>处理人</h6>
                  <img className="signatureImage" src={item.imageUrl} alt="" />
                </div>
              )
            })
          }
        </div>
        {
          this.state.status === 4 ? '' : (
            <div>
              <div className="footer" >
                <div onClick={this.liveSubmit}>中途离开</div>
                <div onClick={this.clickSubmit}>提交</div>
              </div>
              <Drawer direction="bottom" handleClose={() => this.handleClose(1)} open={this.state.open1}>
                <Signature title="本人签字" getSignature={this.getSignature} />
              </Drawer>
              <Drawer direction="bottom" handleClose={() => this.handleClose(2)} open={this.state.open2}>
                <Signature title="客户签字" getSignature={this.getSignature2} />
              </Drawer>
              <Lodding animating={this.state.animating} />
            </div>
          )
        }
        {
          showDialog ? <Dialog handleCancle={this.handleCancle} handleConfirm={this.handleConfirm} title="维保提示" content="当前日期不是计划保养时间是否继续保养？" /> : ''
        }
        {
          showStartDialog ? <Dialog handleCancle={this.handleCancle} handleConfirm={this.handleStartConfirm} title="维保提示" content="是否确定开始保养？" /> : ''
        }
        <Drawer direction="bottom" handleClose={() => { this.handleCloseSignature(3) }} open={this.state.openSignature3}>
          <Signature title="协同人签名" getSignature={this.getSignature3} />
        </Drawer>
      </div>
    )
  }
}

export default connect()(WorkOrderDetails);